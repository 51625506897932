import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AuthenticateRoutes from "./AuthenticateRoutes";
import { useDispatch, useSelector } from "react-redux";
import PopUpModal from "../Components/Payment/PopUpModal";
import classes from "./Navigation.module.css";
import clsx from "clsx";
import SignupForm from "../Components/SignupForm";
import { CModal, CModalBody } from "@coreui/react";
import {
  SET_LOGIN_MODAL,
  SET_SCREEN_VIEW,
  SET_SIGNUP_MODAL,
} from "../Constants";
import CrossIcon from "../assets/cross_round_icon.svg";
import Refund from "../Pages/Refund";
import LoginForm from "../Components/LoginForm";
import Loader from "../Components/Loader";

import Chat from "../Pages/Chat";
import ScanPlayer from "../Pages/ScanPlayer";
import VerifiedSuccessfully from "../Pages/ScanPlayer/VerfiedSuccessfully";

import RefereeDetails from "../Pages/Home/RefereeDetails";
import MemberList from "../Pages/Chat/MemberList";
import TournamentDetail from "../Pages/TournamentDetail";

const Search = lazy(() => import("../Pages/Search"));
const SearchResult = lazy(() => import("../Pages/SearchResult"));
const ResultMap = lazy(() => import("../Pages/ResultMap"));
const BookingScreen = lazy(() => import("../Pages/Booking"));
// const CreateMatchScreen = lazy(() => import("../Components/CreateMatch"));
const AcceptInviteScreen = lazy(() => import("../Pages/AcceptInvite"));
const PaymentScreen = lazy(() => import("../Pages/Payment"));
const DrProfileScreen = lazy(() => import("../Pages/DrProfile"));
const AvailabilityScreen = lazy(() => import("../Pages/Availability"));
const RedirectionScreen = lazy(() => import("../Pages/AppRedirection/index"));
const VerifyMobileAuth = lazy(() => import("../Pages/AppRedirection/VerifyMobileAuth"));
const InviteConfirmation = lazy(() => import("../Pages/AcceptInvite/InviteConfirmationScreen"));

const PracticeProfileScreen = lazy(() =>
  import("../Pages/PracticeProfileScreen")
);
const ViewAppointmentDetails = lazy(() =>
  import("../Pages/ViewAppointmentDetails")
);
const ReshedulePage = lazy(() => import("../Pages/Reshedule"));
const Verify = lazy(() => import("../Pages/Verify"));
const Invites = lazy(() => import("../Pages/Invites"));
const FriendList = lazy(() => import("../Pages/FriendList"));
const LandingPage = lazy(() => import("../Components/Landing"))
const LandingUser = lazy(() => import("../Components/LandingUser"))
const LandingVenue = lazy(() => import("../Components/LandingVenue"))
const RegisterVenue = lazy(() => import("../Components/Landing/registerVenue"))
const PrivacyPolicy = lazy(() => import("../Components/Landing/privacyPolicy"))
const PhoneLogin = lazy(() => import("../Pages/PhoneLogin"));
const OTPPage = lazy(() => import("../Pages/Otp"));
const CreateMatch = lazy(() => import("../Pages/CreateMatch"));
const SearchPlayers = lazy(() => import("../Pages/SearchPlayers"));

export default function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch((state) => state.user);

  const [showCompleteProfile, setShowCompleteProfile] = useState(false);

  const signupState = useSelector((state) => state.signup.signupShow);
  const loginState = useSelector((state) => state.login.loginShow);

  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/Signup" &&
      location.pathname !== "/verify" &&
      user?.identifier
    ) {
      if (!location.search?.includes("?tab=profile") && !user?.active) {
        setShowCompleteProfile(true);
      }
    } else {
      setShowCompleteProfile(false);
    }
  }, [location.pathname, location.search]);

  const handleWindowResize = () => {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth < 768;
    dispatch({ type: SET_SCREEN_VIEW, payload: { isMobile } });
    if (window.innerWidth < 768) {
      dispatch({ type: SET_SIGNUP_MODAL, payload: { signupShow: false } });
      dispatch({ type: SET_LOGIN_MODAL, payload: { loginShow: false } });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/login" element={<PhoneLogin oldUser={true} />} />
          <Route path="/signup" element={<PhoneLogin />} />
          <Route path="/otp" element={<OTPPage />} />
          <Route path="/dashboard" element={<Search />} />

          {/* Previous Flow Routes */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          {/* <Route path="/dashboard" element={<Search />} /> */}

          <Route path="/app-redirect" element={<RedirectionScreen />} />
          <Route path="/verify-mobile-auth" element={<VerifyMobileAuth />} />
          <Route
            path="/"
            element={<Search />}
          />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/landing-user" element={<LandingUser />} />
          <Route path="/landing-venue" element={<LandingVenue />} />
          <Route path="/landing2" element={<RegisterVenue />} />
          <Route path="/tournament/:id" element={<TournamentDetail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/referee/:id" element={<RefereeDetails />} />
          <Route path="/profile/:id" element={<DrProfileScreen />} />
          <Route path="/practice/:id" element={<PracticeProfileScreen />} />
          <Route path="/result" element={<SearchResult />} />
          <Route path="/show-result" element={<ResultMap />} />
          <Route path="/availability" element={<AvailabilityScreen />} />
          <Route path="/booking" element={<BookingScreen />} />
          {/* <Route path="/create-match" element={<CreateMatchScreen />} /> */}
          <Route path="/create-match" element={<CreateMatch />} />
          <Route path="/accept-invite/:matchId" element={<AcceptInviteScreen />} />
          <Route path="/joined/:matchId" element={<InviteConfirmation />} />
          <Route path="/invites" element={<Invites />} />
          <Route path="/friends" element={<FriendList />} />
          <Route exact path="/chat/:channel" element={<Chat />} />
          <Route exact path="/chat/:channel/members" element={<MemberList />} />
          <Route path="/referee/:booking/:player" element={<ScanPlayer />} />
          <Route path="/referee/verified" element={<VerifiedSuccessfully />} />
          <Route path="/search-players" element={<SearchPlayers />} />
          <Route element={<AuthenticateRoutes />}>
            <Route path="/verify" element={<Verify />} />
            <Route path="/payment" element={<PaymentScreen />} />
            <Route
              path="/appointment/:id"
              element={<ViewAppointmentDetails />}
            />
            <Route path="/reshedule/:id" element={<ReshedulePage />} />
            <Route path="/refund" element={<Refund />} />

          </Route>
        </Routes>
      </Suspense>
      <PopUpModal
        setVisible={setShowCompleteProfile}
        visible={showCompleteProfile}
      >
        <h3 className={classes.modalTitle}>Complete Your Profile</h3>
        <p className={classes.modalMessage}>
          Please complete your profile to continue.
        </p>
        <button
          onClick={() => {
            navigate("dashboard?tab=profile");
            setShowCompleteProfile(false);
          }}
          className={clsx(classes.actionBtn, classes.actionBtnPrimary)}
        >
          Okay
        </button>
      </PopUpModal>
      <CModal
        alignment="center"
        visible={signupState}
        onClose={() =>
          dispatch({ type: SET_SIGNUP_MODAL, payload: { signupShow: false } })
        }
        className={clsx("desktopModal", classes.Content_Modal)}
      >
        <CModalBody className={classes.Main_Modal}>
          <div
            className={classes.cancelModalIcon}
            onClick={() =>
              dispatch({
                type: SET_SIGNUP_MODAL,
                payload: { signupShow: false },
              })
            }
          >
            <img src={CrossIcon} alt="CrossIcon" />
          </div>
          <SignupForm />
        </CModalBody>
      </CModal>
      <CModal
        alignment="center"
        visible={loginState}
        onClose={() =>
          dispatch({ type: SET_LOGIN_MODAL, payload: { loginShow: false } })
        }
        className={clsx("desktopModal", classes.Content_Modal)}
      >
        <CModalBody className={classes.Main_Modal}>
          <div
            className={classes.cancelModalIcon}
            onClick={() =>
              dispatch({
                type: SET_LOGIN_MODAL,
                payload: { loginShow: false },
              })
            }
          >
            <img src={CrossIcon} alt="CrossIcon" />
          </div>
          <LoginForm />
        </CModalBody>
      </CModal>
    </>
  );
}
