import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CAlert } from "@coreui/react";
import { signInWithRedirect, signInWithPopup } from "firebase/auth";

import ProgressLoader from "../ProgressLoader";
import { LoginSchema } from "../../Validations";
import { exhangeAuthCode, login } from "../../Api/services/auth";
import { auth, facebookProvider, googleProvider } from "../../Config/firebase";
import { getPlayerById } from "../../Api/services/players";

import GoogleIcon from "../../assets/google-icon.svg";
import FacebookIcon from "../../assets/fb-icon.svg";
import AppleIcon from "../../assets/apple-icon.svg";
import icon_warning from "../../assets/icon_warning.svg";
import icon_success from "../../assets/icon_success.svg";
import classes from "./LoginForm.module.css";
import { useGoogleLogin } from '@react-oauth/google';
import {
  RemoveCircleOutlineRounded,
  RemoveRedEyeSharp,
  VisibilityOffSharp,
  VisibilitySharp,
} from "@mui/icons-material";

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginsucces, setLoginsucces] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();


  let isAuth = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuth) {
      navigate("/login");
    }
  }, [isAuth, auth]);

  const handleSubmit = async (values, type) => {
    setLoading(true);
    try {
      let res = await login({
        email: values.email,
        password: values.password,
        uid: values.uid,
        name: values.name,
        photo: values.photo,
        role: values.role,
        provider: values.provider,
        socialAuth: type === "thirdParty",
      });

      localStorage.setItem(
        "user",
        JSON.stringify({
          ...res.data,
          socialUID: values.uid,
          socialAuth: type === "thirdParty",
        })
      );
      localStorage.setItem("token", res?.data?.token);
      let user = await getPlayerById(res?.data?.uid);

      // sending email to webview for push notifications
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(user?.email);
      }

      dispatch({ type: "SAVE_USER_INFO", payload: user });
    } catch (error) {
      setError(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      let res = await signInWithPopup(auth, googleProvider);
      handleSubmit(
        {
          email: res?.user?.email,
          password: res?.user?.uid,
          uid: res?.user?.uid,
          name: res?.user?.displayName,
          photo: res?.user?.photoURL,
          provider: "google",
          role: "user",
        },
        "thirdParty"
      );
    } catch (error) {
      setError(error.response?.data?.message);
    }
  };
  const handleSocialAuth = async (values) => {
    try {
      let res = await login({
        email: values.email,
        password: values.password,
        uid: values.uid,
        name: values.name,
        photo: values.photo,
        role: values.role,
        provider: values.provider,
        socialAuth: true,
      });
      localStorage.setItem(
        "user",
        JSON.stringify({ ...res.data, socialAuth: true, socialUID: values.uid })
      );
      localStorage.setItem("token", res?.data?.token);
      let user = await getPlayerById(res.data?.uid);
      dispatch({ type: "SAVE_USER_INFO", payload: user });
    } catch (error) {
      setError(
        error.response?.data?.message ||
        "Something went wrong. Please try again"
      );
    }
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    onSuccess: (response) => {
    },
    onError: (error) => {
      setError(error.response?.data?.message || 'Something went wrong. Please try again');
    },
  });

  const verifyLogin = async (code) => {
    try {
      setLoading(true);
      const res = await exhangeAuthCode(code);
      const { data } = res;
      const { userInfo } = data
      if (userInfo) {
        handleSubmit(
          {
            email: userInfo?.email,
            password: userInfo?.id,
            uid: userInfo?.id,
            name: userInfo?.name,
            photo: userInfo?.picture,
            provider: "google",
            role: "user",
          },
          "thirdParty"
        );
      }
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.message || 'Something went wrong. Please try again');
    }
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const authorizationCode = queryParams.get('code');
    if (authorizationCode) {
      verifyLogin(authorizationCode);
    }
  }, [location]);

  const handleLoginWithFacebook = async () => {
    try {
      let res = await signInWithPopup(auth, facebookProvider);
      handleSocialAuth({
        email: res?.user?.email,
        password: res?.user?.uid,
        uid: res?.user?.uid,
        name: res?.user?.displayName,
        photo: res?.user?.photoURL,
        provider: "facebook",
        role: "user",
      });
    } catch (error) {
      setError(
        error.response?.data?.message ||
        "Email already linked with another account"
      );
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className={classes.form}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className={classes.header}>
              <h2>Welcome!</h2>
              <p>Log in to your account</p>
            </div>
            <div>
              <label className={errors.email}>Email</label>
              <Field
                name="email"
                type="text"
                placeholder="Email"
                className={errors.email ? classes.error_border : ""}
              />
              {errors.email ? (
                <div className={classes.errorMessage}>{errors.email}</div>
              ) : null}
            </div>
            <div className={classes.password_container}>
              <label className={errors.password}>Password</label>
              <div style={{ position: "relative" }}>
                <Field
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className={errors.password ? classes.error_border : ""}
                  autoComplete="email"
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "44%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <VisibilityOffSharp /> : <VisibilitySharp />}{" "}
                </span>
              </div>
              {errors.password ? (
                <div className={classes.errorMessage}>{errors.password}</div>
              ) : null}
            </div>
            {!loginsucces &&
              Error &&
              !loading &&
              !errors.password &&
              !errors.email ? (
              <CAlert className={classes.warning_alert}>
                <img src={icon_warning} alt="Warning Icon" />
                {Error}{" "}
              </CAlert>
            ) : null}
            {!Error &&
              loginsucces &&
              !loading &&
              !errors.password &&
              !errors.email ? (
              <CAlert className={classes.success_alert}>
                <img src={icon_success} alt="Success Icon" />
                You signed up successfully
              </CAlert>
            ) : null}
            <button
              disabled={loading}
              type="submit"
              className={classes.LoginBtn}
            >
              {loading ? <ProgressLoader /> : "Log in"}
            </button>
          </Form>
        )}
      </Formik>
      <div className={classes.divider}>
        <div>or</div>
      </div>
      <button onClick={googleLogin} className={classes.googleBtn} disabled={loading}>
        <img className={classes.mx1} src={GoogleIcon} alt="Google" />
        <span>Log in with Google</span>
      </button>
      <button onClick={handleLoginWithFacebook} className={classes.facebookBtn} disabled={loading}>
        <img className={classes.mx1} src={FacebookIcon} alt="Facebook" />
        <span>Log in with Facebook</span>
      </button>
      <button className={classes.appleBtn} disabled={loading}>
        <img className={classes.mx1} src={AppleIcon} alt="Apple" />
        <span>Log in with Apple</span>
      </button>
      <div className={classes.footer}>
        <p>
          Don’t have an account?{" "}
          <span
            onClick={() => {
              navigate("/signup");
            }}
          >
            Sign up
          </span>
        </p>
      </div>
    </div>
  );
}
