import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

import Navigation from './Router';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import { SAVE_USER_INFO } from './Constants';
import 'react-tooltip/dist/react-tooltip.css';
import { getPlayerById, updateBanStatus } from './Api/services/players';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);

  const checkLocationPermission = async () => {
    try {
      const result = await navigator.permissions.query({ name: 'geolocation' });
      return result.state === 'granted';
    } catch (error) {
      console.error('Error checking location permission:', error);
      return false;
    }
  };

  const getUserByID = async () => {
    let localUser = JSON.parse(localStorage.getItem('user'));

    if (localUser && !user?.uid) {
      let uid = localUser?.socialAuth ? localUser?.uid : localUser?.uid;
      let data = await getPlayerById(uid);
      if (data?.banStatus === 'banned' && new Date(data?.banUpliftDate) < new Date()) {
        await updateBanStatus(uid)
        data = await getPlayerById(uid);
        // Dispatch the updated user data
        dispatch({ type: SAVE_USER_INFO, payload: data });
      } else {
        // Dispatch the fetched user data
        dispatch({ type: SAVE_USER_INFO, payload: data });
      }
    }
    setIsLoading(false); // Stop loading after fetching user data
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUserByID();
    } else {
      setIsLoading(false); // No token, stop loading

    }

    checkLocationPermission().then((hasPermission) => {
      if (!hasPermission) {
        navigator.geolocation.getCurrentPosition(
          () => {
            // Success callback - you can add handling here if needed
          },
          (error) => {
            console.error('Error getting location:', error);
          }
        );
      }
    });

    // const { host, pathname } = window.location;
    // if (host === "localhost" && pathname !== "/landing-user" && pathname !== "/landing-venue" && pathname !== "/dashboard" && pathname !== "/") {
    //   window.location.href = "/";
    // }
  }, []);

  return (
    <>
      <BrowserRouter>
        <GoogleOAuthProvider clientId='282837265984-qd7qjnihqhuntjcbvafpub2o1k1pe3s0.apps.googleusercontent.com'>
          <ToastContainer />
          <Navigation />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
