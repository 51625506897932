import api from '../index.js'

export const getAllTournaments = async () => {
    try {
        const response = await api.get(`/tournament`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const joinTournament = async (body) => {
    try {
        const response = await api.post('/payment/tournament', body);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}